import React from 'react'
import './Faqs.css'
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "WHAT IS STAKING?",
            content: `When you stake your $OC,  you auto-magically receive NFT as $POS that will be used as proof-of-stake. With the $POS received you are able to stake for rewards in the STAKING Pool.`,
        },
        {
            title: "WHAT HAPPENS IF I SELL MY POS TOKEN?",
            content: `Please do not sell/exchange your $POS NFT. If you sell, you won't be able to unstake your $OC.`,
        },
        {
            title: "HOW CAN I STAKE $OC?",
            content: `You can stake your OC on the "Staking" page once our Dapp is LIVE.`,
        },
        {
            title: "HOW DO I CONNECT MY WALLET TO Oxchange Finance AMM?",
            content: `In order to connect your wallet you have to click on the "Connect Wallet" button on the top-right corner of the page. After that, look for the wallet you want to connect to Oxchange Finance and click "connect". Approve the connection and you're good to go.`,
        },
        {
            title: "HOW DO I SET UP MY WALLET ON POLYGON CHAIN?",
            content: `Trust Wallet: When you go into the dAPP in the top-right corner you can see the different blockchains available, click on it then choose Polygon Chain Mainnet. MetaMask: Create a custom RPC with the following: Polygon Mainnet in the Network Name field, URL <a href="https://polygon-rpc.com/" target="_blanck">https://polygon-rpc.com/</a> in the New RPC URL field, 137 in Chain ID field, MATIC in Currency Symbol field and <a href="https://polygonscan.com/" target="_blanck">https://polygonscan.com/</a> in Block Explorer URL field.`,
        },
        {
            title: "WHEN WILL YOU OPEN MORE POOLS?",
            content: `There will always be an announcement before the launch of new pools. Join the announcements Telegram group to be the first that finds out.`,
        },
        {
            title: "HOW DO I GET AIRDROPS?",
            content: `When there is any official airdrop going on it will be announced along with the requirements. Please remember anyone can airdrop tokens to users since every transaction is public on PolygonScan. Do your own due diligence when it comes to non-official airdrops.`,
        },
        {
            title: "WHY DOES IT SAY I HAVE NO MATIC BALANCE?",
            content: `You're most likely not connected to Polygon Chain within your wallet.`,
        },
        {
            title: "WHAT IS THE MAX SUPPLY OF OC?",
            content: `$OC maximum supply is Limited till 21-million OC`,
        },

    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#02ffff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#02ffff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const Faqs = () => {
    return (
        <section className="faqs" id='faqsSrl'>
            <div className="container">
                <div className='titlebox'>
                    <h6>FAQ's</h6>
                    <h2>Quick Questions</h2>
                    <p>Here are Some Important things you should know!.</p>
                </div>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default Faqs
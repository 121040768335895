import React, { useEffect } from 'react'
import { useState } from 'react'
// import { NavLink } from 'react-router-dom'
import logo from '../../Assets/images/logo.svg'
import './header.css'
import { Link } from 'react-scroll';

// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import toast, { Toaster } from 'react-hot-toast';

// const notify = () => toast.success('Copied Successfully!')
const Header = () => {
    const [showNavbar, setShowNavbar] = useState(false)
    const [showHamburger, setHamburger] = useState(false)
    const [OcPrice, setOcPrice] = useState()


    function Price() {
        try {
            
            const data = fetch(
                "https://oc-price-api.vercel.app/oc-price")
                .then((res) => res.json())
                .then((json) => {
                    setOcPrice((Math.floor((json.price) * 100) / 100).toFixed(2))
                })
        } catch (error) {
            console.log(error);
        }
    }


    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
        setHamburger(!showHamburger)
    }


    useEffect(()=>{
        Price()
    },[])

    useEffect(() => {
        const interval = setInterval(() => {
        Price()
        }, 3000);
        return () => {
          clearInterval(interval);
        };
      }, []); 

    return (
        <>
            <header className={`${showNavbar && 'openmenu'}`}>
                <div className={`clipHeader`}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-4 col-md-2 text-start">
                                <div className="logo">
                                    <img src={logo} alt="logo" width="140" height="140" />
                                </div>
                            </div>
                            <div className={`col-7 text-center d-none d-md-block`}>
                                <ul className='menu list-unstyled m-0 p-0'>
                                    <li>
                                        <Link to="aboutusSrl" smooth={true} duration={0}>About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="featuresSrl" smooth={true} duration={0}>Features</Link>
                                    </li>
                                    <li>
                                        <Link to="roadmapSrl" smooth={true} duration={0}>Roadmap</Link>
                                    </li>
                                    <li>
                                        <a href="https://sale.oxchange.finance/" target="_blank" rel="noreferrer">Sale</a>
                                    </li>
                                    <li>
                                        <Link to="faqsSrl" smooth={true} duration={0}>Faqs</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-8 col-md-3 text-end">
                                <div className='rightboxmenu'>
                                    <ul className='balancebox list-unstyled m-0'>
                                        <li>Price<strong>$ {OcPrice}</strong></li>
                                        {/* <li onClick={notify}>
                                            <CopyToClipboard text="0x3ca3903bf9020e25a6b5141a4d9d6247f0c710ca">
                                                <span className='oc_btn'>0X3c....10ca</span>
                                            </CopyToClipboard>
                                        </li> */}
                                    </ul>
                                    <div className={`hamburger d-md-none ${showHamburger && 'is-active'}`} onClick={handleShowNavbar}>
                                        <span className="line"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="before">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </header>
            {/* Mobile Menu Start */}
            <div className={`menubar ${showNavbar && 'mobileMenu'}`}>
                <ul className='menu list-unstyled m-0 p-0'>
                    <li>
                        <Link to="aboutusSrl" smooth={true} duration={0} onClick={handleShowNavbar}>About Us</Link>
                    </li>
                    <li>
                        <Link to="featuresSrl" smooth={true} duration={0} onClick={handleShowNavbar}>Features</Link>
                    </li>
                    <li>
                        <Link to="roadmapSrl" smooth={true} duration={0} onClick={handleShowNavbar}>Roadmap</Link>
                    </li>
                    <li>
                        <Link to="faqsSrl" smooth={true} duration={0} onClick={handleShowNavbar}>Faqs</Link>
                    </li>
                </ul>
            </div>
            {/* Mobile Menu End */}
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}
            /> */}
        </>
    )
}

export default Header
import React from 'react';
import './popup.css'; // Assuming you will style the popup using CSS
import popupimg from '../../Assets/images/popupimg.png'
const Popup = ({ show, onClose, text, link }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-button" onClick={onClose}>×</span>
        <img src={popupimg} className='popupimg' alt="" />
        <p className='descpopup'>We're excited to announce that your new decentralized future OxWallet is live! Download Now and join the decentralized revolution!</p>
        <a href={'https://play.google.com/store/apps/details?id=com.vedge.oxwallet&pli=1'} target='_blank' className="link-button">Download Now</a>
      </div>
    </div>
  );
};

export default Popup;